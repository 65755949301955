import WebServiceRequest from '../Api/WebServiceRequest'

class OrderChangeStatusRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setId(data){
        super.setTag('Order/ChangeOrderStatus?orderId='+data.orderId+'&status='+data.status)
    }
}
class OrderCreateByAdmin extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Order/CreateByAdmin')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class OrderCreateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Order/Create')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class OrderCreateForAdminRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Order/CreateForAdmin')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class OrderDeleteRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setId(id){
        super.setTag('Order/Delete?id='+id)
    }
}
class OrderGetAllRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Order/GetAll?pageNumber')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class OrderGetByUserIdRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Order/GetByUserId')
    }
}
class OrderGetProductsForStudents extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setParams(data){
        super.setTag('Order/GetProductsForStudents?searchCommand='+data.searchCommand+'&pageNumber='+data.pageNumber+'&count='+data.count)
    }
}
class OrderGetRegisteredUserRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Order/GetRegisteredUser')
    }
    setParams(data){
       super.setRequestParam(data)
    }
}
class OrderGetRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setId(id){
        super.setTag('Order/Get?orderId='+id)
    }
}
export {
    OrderChangeStatusRequest,
    OrderCreateRequest,
    OrderCreateByAdmin,
    OrderDeleteRequest,
    OrderGetAllRequest,
    OrderGetByUserIdRequest,
    OrderGetProductsForStudents,
    OrderGetRegisteredUserRequest,
    OrderGetRequest,
    OrderCreateForAdminRequest
};
